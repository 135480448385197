<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'investmentSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content bg-white border0">
          <div class="cancel-watermark" v-if="details.status == 1">
            <div class="water-mark-content">Cancelled</div>
          </div>
          <div class="modal-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Investment Slip - #{{details.investment_id}}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print" @click="print" title="Print"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download" title="Download"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  title="Close"
                  @click="$store.dispatch('modalClose', 'addSlip')"
                >
                  <i class="fas fa-times" title="Close"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
             <p class="font-xxxl mb-0">{{authData.org_detail.name}}</p>
               <p class="mb-0 font-print-xxs">
                  {{details.branch.address}}
                  <br />Tel No:{{authData.org_detail.phone}} / {{authData.org_detail.email}}
                  <br />PAN:{{authData.org_detail.pan_vat}}
               </p>
              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-4 col-lg-4 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">Investment</label>
                <!--    <h6 class="tx-15 mg-b-10">Juan Dela Cruz</h6> -->
                <p class="mg-b-0">Account Type : {{details.account_type.title}}</p>
                <p class="mg-b-0">
                  Account Name : {{details.account.account_name}}
                </p>
              </div>
              <div class="col-sm-4 col-lg-4 order-1 order-sm-0">
                <p class="text-center font-l m-0">{{details.investment_type==1?'Withdraw Investment ':'Add Investment'}}</p>
                <hr class="print-sm-hr print-hr-margin" />
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4 mg-t-15">
                <p class="mg-b-0 text-right">Investment Id : #{{details.investment_id}}</p>
                <p class="mg-b-0 text-right">Date : {{details.date}}</p>
              </div>
              <!-- col -->
            </div>
            <hr>
            <!-- row -->
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div class="col-sm-7 col-lg-7 order-2 order-sm-0 mg-t-40 mg-sm-t-0">
                <!-- Amount-in-word -->
                <div class="footer_detail mt-0 mb-2 p-2">
                  <label class="content-label mb-1">Amount In Word</label>
                  <p
                    class="mg-b-0"
                  >{{toWord(details.amount)}}only</p>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0 table_lr_border color-secondary">
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">Payment Type</th>
                        <th scope="col" class="border-right_1">Ref.</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{details.payment_method.title}} <span v-if="details.bank_id">{{details.bank.bank}}</span></td>
                        <td>{{details.transaction_id}}</td>
                        <td>{{parseDigitForSlip(details.amount)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4 order-1 order-sm-0 color-secondary mb-0 mt-0">
                <ul class="list-unstyled lh-6 mb-0">
                  <li class="d-flex justify-content-between">
                    <span>Investment Amount</span>
                    <span>{{parseDigitForSlip(details.amount)}}</span>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>
            
            
            <hr />
            <div class="footer_note">
              <label class="content-label mb-1">Note</label>
              <p class="mb-2">
                {{details.description}}
              </p>
            </div>
            <p class="text-right">User: {{details.user.name}}</p>
          </div>
           <div v-if="details.status == 1">
                <hr>
                <div class="footer_note">
                  <label class="content-label mb-1 tx-danger">Cancelation Detal</label>
                  <p class="mg-b-0 font-print-xxs">
                    Cancellation Date : {{details.cancel_date}}
                  </p>
                  <p class="mg-b-0 font-print-xxs" v-if="details.cancel_user_id">
                    Cancellation User : {{details.user.name}}
                  </p>
                  <p class="mb-2 text-sm-left font-print-xxs">
                    Remark: {{details.remarks}}
                  </p>
                </div>
                <hr class="print-display-only-block">
              </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        details: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataId",
      "modalId",
      "modalState",
      "authData",
    ]),
    ...mapGetters("investments",["investmentLists","investmentElements"])
  },
  methods: {
   print(){
     window.print();  
   }
  },
  watch: {
   modalId(value){
     if(value == 'investmentSlip'){
       let id = this.dataId;
       this.details = this.investmentLists.find(function(data){
         return data.id == id;
       })
     }
   }
  },
};
</script>